/* Ensure full height layout */
.app-layout {
  display: flex;
  height: 100vh;
}

/* Sidebar (Dashboard) */
.dashboard {
  width: 250px;
  min-height: 100vh;
  background-color: #333;
  color: white;
  position: fixed;
  transition: transform 0.3s ease-in-out;
}

/* Main content */
.main-content {
  margin-left: 250px; /* Default space for sidebar */
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}

/* Navbar */
.navbar {
  height: 60px;
  background-color: #f4f4f4;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Page content area */
.page-content {
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
}

/* 🔹 Responsive Design for Tablets & Mobile */
@media (max-width: 768px) {
  .dashboard {
      width: 100%;
      height: auto;
      position: relative;
  }

  .main-content {
      margin-left: 0;
      width: 100%;
  }
}

.main-container {
  display: flex;
  flex-direction: row;
}

.content {
  flex-grow: 1;
  padding: 16px;
  transition: margin 0.3s ease-in-out;
}

@media (max-width: 1024px) { /* For iPads */
  .main-container {
    flex-direction: column; /* Stack navbar and sidebar vertically */
  }
  .content {
    margin-left: 0; /* Remove space when sidebar is collapsed */
  }
}
